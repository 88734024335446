<template>
  <div class="stream-chat-container" :class="col">
    <Slido
      :currentUser="currentUser"
      :settings="settings"
      :code="code"
      class="chat"
      v-if="isSlido"
    />
    <VimeoChat
      :currentUser="currentUser"
      :code="code"
      class="chat"
      v-if="isVimeo"
    />
    <ArenaChat
      :currentUser="currentUser"
      :code="code"
      class="chat"
      v-if="isArena"
    />
    <EmbeddedChat
      :currentUser="currentUser"
      :code="code"
      :roomCode="roomCode"
      class="chat"
      v-if="isEmbeddedChat"
    />
  </div>
</template>
<script>
import VimeoChat from "@/components/chat/VimeoChat.vue";
import Slido from "@/components/chat/Slido.vue";
import ArenaChat from "@/components/chat/ArenaChat.vue";
import EmbeddedChat from "@/components/chat/EmbeddedChat.vue";

export default {
  name: "Home",
  props: ["type", "code", "col", "roomCode"],
  components: {
    Slido,
    VimeoChat,
    ArenaChat,
    EmbeddedChat
  },
  computed: {
    isSlido() {
      return this.type?.toLowerCase() === "slido";
    },
    isVimeo() {
      return this.type?.toLowerCase() === "vimeo";
    },
    isArena() {
      return this.type?.toLowerCase() === "arena";
    },
    isEmbeddedChat() {
      return this.type?.toLowerCase() === "embedded-chat";
    },
    currentUser() {
      return this.$store.getters["Auth/currentUser"];
    },
    settings() {
      return this.$store.state.Config.all;
    },
  },
};
</script>
<style lang="scss">
.stream-chat-container {
  position: relative;
  width: 100%;
  height: 700px;
  padding-left: 0px;
  padding-right: 0px;
  margin-left: 0px;
  margin-right: 0px;
  .chat {
    height: 100%;
    min-width: 265.455px;
  }
}
</style>
